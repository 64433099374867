import React, { Fragment, useEffect, useState } from 'react';
import { Grid, Paper, Typography, makeStyles, FormControl, InputLabel, Select, FormHelperText, TextField, Button, Switch, FormControlLabel, 
  CircularProgress as Loading } from '@material-ui/core';
import moment from 'moment';

import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'

import * as actions from '../../../../../../store/actions/index'
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DatePicker, TimePicker } from '@material-ui/pickers';

const useStyles = makeStyles(theme => ({
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  formRoot: {
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2, 4, 3)
  }
}));

const schema = yup.object().shape({
  // status: yup.string().required(),
  // description: yup.string().required()
})

const AddReminder = props => {
  const history = useHistory();
  const classes = useStyles();
  const { assetDetail, onStoreReminder, token, errorReminder, onClearErrorReminder, loading } = props;

  useEffect(() => {
    onClearErrorReminder()
  },[onClearErrorReminder])

  let button = (loading ? <Loading /> : (
    <Button type="submit" variant="contained" style={{ backgroundColor: '#FF9300', color: '#FFFFFF' }} fullWidth disableElevation>
      Simpan
    </Button>
  ))

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema)
  })

  const onSubmit = data => {
    data.uuid = assetDetail.uuid;
    data.reminder_date = moment.utc(selectedDate).format('YYYY-MM-DD');
    if(data.reminder_time){
      let remainder_time = new Date(selectedTime);
      let remainder = new Date(selectedDate);
      remainder.setHours(remainder_time.getHours());
      remainder.setMinutes(remainder_time.getMinutes());
      remainder.setSeconds(1); 
      
      data.reminder_date = moment.utc(remainder).format('YYYY-MM-DD HH:mm:ss');

    }else {
      let reminder_date = new Date(selectedDate);
      reminder_date.setHours(0);
      reminder_date.setMinutes(0);
      reminder_date.setSeconds(1);
      data.reminder_date = moment.utc(reminder_date).format('YYYY-MM-DD HH:mm:ss');
      
    }
    onStoreReminder(data, token, history)
  }

  const handleChange = (event) => {
    const target = event.target.name
    event.persist()
    
    if(event.target.type === 'checkbox'){
      setFormState((formState) => ({
        ...formState,
        [target]: {
          value: event.target.checked
        }
      }))
    }else{
      setFormState((formState) => ({
        ...formState,
        [target]: {
          value: event.target.value
        }
      }))
    }
  }

  const [formState, setFormState] = useState({
    // title: {
    //   value: ''
    // },
    body: {
      value: ''
    },
    reminder_date: {
      value: ''
    },
    is_using_time: {
      value: ''
    },
    reminder_time: {
      value: ''
    },
    status_repeat: {
      value: ''
    },
    switch: {
      value: true
    }
  });

  const [selectedDate, handleDateChange] = useState(new Date());
  const [selectedTime, handleTimeChange] = useState(new Date());

  let switched = '';
  if(formState.switch.value === true){
    switched = (
      <Fragment>
        <TextField
          variant="standard"
          name="is_using_time"
          inputRef={register}
          error={!!errors.is_using_time}
          helperText={errors.is_using_time && errors.is_using_time.message}
          defaultValue={1}
          type="hidden"
          onChange={handleChange}
        />
        <div className={classes.row}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TimePicker
                clearable
                fullWidth
                inputVariant="outlined"
                ampm={false}
                label="Waktu Pengingat"
                name="reminder_time"
                error={errorReminder.reminder_time}
                helperText={errorReminder.reminder_time && errorReminder.reminder_time[0]}
                inputRef={register}
                value={selectedTime}
                onChange={handleTimeChange}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FormControl
                // error={errors.status_repeat && true}
                error={errorReminder.status_repeat && true}
                variant="outlined" className={classes.formControl} fullWidth>
                <InputLabel htmlFor="outlined-age-native-simple">Periode Perulangan</InputLabel>
                <Select
                  native
                  defaultValue={formState.status_repeat.value}
                  onChange={handleChange}
                  label="Periode Perulangan"
                  inputProps={{
                    name: 'status_repeat',
                    id: 'outlined-age-native-simple'
                  }}
                  name="status_repeat"
                  inputRef={register}
                >
                  <option aria-label="None" value="" />
                  <option value="0">Tidak Pernah</option>
                  <option value="1">Harian</option>
                  <option value="2">Mingguan</option>
                  <option value="3">Bulanan</option>
                  <option value="4">Tahunan</option>
                </Select>
                {/* <FormHelperText>{errors.status_repeat && errors.status_repeat.message}</FormHelperText> */}
                <FormHelperText>{errorReminder.status_repeat && errorReminder.status_repeat[0]}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </Fragment>
    )
  }else{
    switched = (
      <TextField
        variant="standard"
        name="status_repeat"
        inputRef={register}
        error={!!errors.status_repeat}
        helperText={errors.status_repeat && errors.status_repeat.message}
        defaultValue={0}
        type="hidden"
        onChange={handleChange}
      />
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Paper className={classes.formRoot}>
            <Typography>Pengingat</Typography>
            {/* <div className={classes.row}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    label="Judul Pengingat"
                    variant="outlined"
                    name="title"
                    fullWidth
                    inputRef={register}
                    error={errorReminder.title}
                    helperText={errorReminder.title && errorReminder.title[0]}

                    defaultValue={formState.title.value}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </div> */}
            <div className={classes.row}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    label="Deskripsi"
                    variant="outlined"
                    name="body"
                    fullWidth
                    multiline
                    rows={4}
                    inputRef={register}
                    error={errorReminder.body}
                    helperText={errorReminder.body && errorReminder.body[0]}

                    defaultValue={formState.body.value}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </div>
            <div className={classes.row}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                {/* <KeyboardDatePicker
                  autoOk
                  fullWidth
                  name="reminder_date"
                  inputRef={register}
                  error={errorReminder.reminder_date}
                  helperText={errorReminder.reminder_date && errorReminder.reminder_date[0]}
                  variant="inline"
                  inputVariant="outlined"
                  label="Tanggal Pengingat"
                  format="DD/MM/yyyy"
                  value={selectedDate}
                  InputAdornmentProps={{ position: "start" }}
                  onChange={date => handleDateChange(date)}
                /> */}
                <DatePicker
                  variant="dialog"
                  format="DD/MM/yyyy"
                  views={["year", "month", "date"]}
                  label="Tanggal Pengingat"
                  error={errorReminder.reminder_date}
                  helperText={errorReminder.reminder_date && errorReminder.reminder_date[0]}
                  value={selectedDate}
                  onChange={date => handleDateChange(date)}
                  fullWidth
                  name="reminder_date"
                  inputRef={register}
                />
                </Grid>
              </Grid>
            </div>
            <div className={classes.row}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formState.switch.value}
                        onChange={handleChange}
                        color="primary"
                        name="switch"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    }
                    label="Waktu Spesifik?"
                  />
                </Grid>
              </Grid>
            </div>
            {switched}
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item lg={8} md={8} sm={8} xs={8} />
        <Grid item lg={4} md={4} sm={4} xs={4} >
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Button variant="contained" fullWidth disableElevation onClick={props.closedModalDialog}>
                    Batal
                  </Button>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  {button}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </form>
  )
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    errorReminder: state.assetDetail.errorReminder,
    loading: state.assetDetail.loading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onStoreReminder: (storeData, token, history) => dispatch(actions.storeReminderAsset(storeData, token, history)),
    onClearErrorReminder: () => dispatch(actions.clearErrorReminder())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddReminder);