import * as firebase from "firebase/app";
import "firebase/messaging";

const initializedFirebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyAOeR0tdcm6RKmHJh2PeIhAhvuo5ZSy-yI",
  authDomain: "eoa-manajemen-aset.firebaseapp.com",
  databaseURL: "https://eoa-manajemen-aset.firebaseio.com",
  projectId: "eoa-manajemen-aset",
  storageBucket: "eoa-manajemen-aset.appspot.com",
  messagingSenderId: "640712081621",
  appId: "1:640712081621:web:4649a0c1d04188e475e25c",
  // measurementId: "G-0QGZWWZQ73"
});

let messaging;
if (firebase.messaging.isSupported()) {
  messaging = initializedFirebaseApp.messaging();
  messaging.usePublicVapidKey(
    "BOLy4PsB5OFoz4N6lFhZUXbw5bYstIMos-Z721p3Pq4dZdhubd37YJjiwDMfYStDShtIpUsiycgOcTpOKmiN_mM"
  );
}

export { messaging };
