import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'

import * as actions from '../../../store/actions/index'
import { connect } from 'react-redux'

import Loading from '../../../components/UI/Loading/CircularProgress/CircularProgress'
import { Redirect } from 'react-router-dom'
import { Hidden } from '@material-ui/core'

import { messaging } from "../../../init-fcm";

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh'
  },
  image: {
    backgroundColor: '#011747',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    marginTop: theme.spacing(8),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    paddingTop: 30
  },
  textField: {
    [`& fieldset`]: {
      borderRadius: 100,
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#FF9300',
    '&:hover': {
      backgroundColor: '#ef8b03'
    },
    color: '#FFFFFF',
    borderRadius: 100
  },
  contentName: {
    // padding: '200px 20px 0px',
    padding: theme.spacing(15, 15)
  },
  center: {
    color: '#FFFFFF',
    fontSize: 50,
    paddingBottom: 20
  },
  center2: {
    color: '#FFFFFF',
    fontSize: 60
  }
}))

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required().min(6)
})

const Login = props => {
  let [tokenFirebase, setTokenFirebase] = useState('');
  const fetchData = async () => {
    messaging.requestPermission()
    .then(async function() {
      setTokenFirebase(await messaging.getToken());
      // console.log(token);
    })
    .catch(function(err) {
      console.log("Unable to get permission to notify.", err);
    });
    // navigator.serviceWorker.addEventListener("message", (message) => console.log(message.data));
  }

  // // console.log(tokenFirebase)

  useEffect(() => {
    fetchData();
  })
  
  const classes = useStyles()

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema)
  })

  const onSubmit = event => {
    props.onAuth(event.email, event.password, tokenFirebase)
  }

  let loading = null
  if (props.loading) {
    loading = <Loading />
  }

  let authRedirect = null
  if (props.isAuthenticated) {
    authRedirect = <Redirect to={props.redirectPath} />
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} >
        <Hidden only={["xs"]}>
          <div >
            <center>
              <img src={require('../../../assets/image/logo_ma512.png').default} alt="logo-wakaf" width="auto" height="auto" />
            </center>
            {/*<Typography variant="h1" className={classes.center}>*/}
            {/*  Manajemen Aset*/}
            {/*</Typography>*/}
            {/*<Typography variant="h1" className={classes.center}>*/}
            {/*  EOA Tech*/}
            {/*</Typography>*/}
          </div>
        </Hidden>
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        
        <div className={classes.paper}>
          <div container>
            <div item lg={12} sm={12} md={12} xs={12}>
              {/*<center>*/}
              {/*<img src={require('../../../assets/image/logo_ma512.png').default} alt="logo-wakaf" width="50%" height="auto" />*/}

              {/*</center>*/}
              
            </div>
          </div>
          {/* <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar> */}
          <Typography component="h1" variant="h3"className={classes.avatar}>
            Selamat Datang
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              inputRef={register}
              error={!!errors.email}
              helperText={errors.email && errors.email.message}
              className={classes.textField}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              inputRef={register}
              error={!!errors.password}
              helperText={errors.password && errors.password.message}
              className={classes.textField}
            />
            {loading}
            {authRedirect}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
          </form>
        </div>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.token !== null,
    redirectPath: state.auth.authRedirectPath
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onAuth: (email, password, firebaseToken, type) => dispatch(actions.auth(email, password, firebaseToken)),
    onAlert: (message, alertType) => dispatch(actions.setAlert(message, alertType))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
